//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';

import arrowOrange from '@/assets/imgs/arrow-orange.svg';

export default {
  name: 'ProjectForm',

  props: {
    text: {
      type: String,
      default: 'What can we help you with?'
    }
  },

  data() {
    return {
      arrowOrange,

      planning: false,
      design: false,
      development: false,
      content: false,
      marketing: false,
      other: false,
      name: '',
      phone: '',
      email: '',
      message: '',

      error: false,
      thanks: false,
      submitting: false
    };
  },

  computed: {
    form() {
      return {
        input_1_1: this.planning ? 'UX/UI Planning' : false,
        input_1_2: this.design ? 'Design' : false,
        input_1_3: this.development ? 'Development' : false,
        input_1_4: this.content ? 'Content Creation' : false,
        input_1_5: this.marketing ? 'Marketing & Analytics' : false,
        input_1_6: this.other ? 'Other' : false,
        input_2: this.name,
        input_5: this.phone,
        input_6: this.email,
        input_7: this.message,
        input_8: this.$route.path
      };
    },

    submitBg() {
      return `background-image: url("${arrowOrange}");`;
    }
  },

  methods: {
    async formSubmit(e) {
      e.preventDefault();
      // console.log(this.form);

      this.$wpJson.gf.submit(1, this.form)
        .then((response) => {
          // console.log(response);
          if (response.is_valid === true) {
            // you had a successful call
            this.thanks = true;
            this.submitting = false;

            this.planning = false;
            this.design = false;
            this.development = false;
            this.content = false;
            this.marketing = false;
            this.other = false;
            this.name = '';
            this.phone = '';
            this.email = '';
            this.message = '';
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.is_valid === false) {
              this.error = true;
              this.submitting = false;
            }
          }
        });
    }
  }
};
