//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import abstract from '@/assets/imgs/abstract.svg';
import abstract2 from '@/assets/imgs/abstract2.svg';
import abstract3 from '@/assets/imgs/abstract3.svg';
import arrowOrange from '@/assets/imgs/arrow-orange.svg';
import smallAbstract0 from '@/assets/imgs/small-abstract-1.svg';
import smallAbstract1 from '@/assets/imgs/small-abstract-2.svg';
import smallAbstract2 from '@/assets/imgs/small-abstract-3.svg';
import smallAbstract3 from '@/assets/imgs/small-abstract-4.svg';
import smallAbstract4 from '@/assets/imgs/small-abstract-5.svg';
import smallAbstract5 from '@/assets/imgs/small-abstract-6.svg';
import { page } from '@/mixins/wp-post';

export default {
  name: 'KnockHomepage',

  mixins: [page('home')],

  data() {
    return {
      arrowOrange,
      smallAbstract: [smallAbstract0, smallAbstract1, smallAbstract2, smallAbstract3, smallAbstract4, smallAbstract5]
    };
  },

  computed: {
    bannerSectionBg() {
      return `background: #131212 url("${abstract}") no-repeat scroll top right`;
    },

    middleSectionBg() {
      return `background: #131212 url("${abstract2}") no-repeat scroll 0%`;
    },

    bottomSectionBg() {
      return `background: url("${abstract3}") no-repeat scroll 100%`;
    }
  },

  methods: {
    scrollToWorks() {
      const works = document.querySelector('.s4').offsetTop;
      window.scrollTo({ top: works, behavior: 'smooth' });
    }
  }
};
