import yoastToVueMeta from '@/utils/yoast-to-vue-meta';

/**
 * @callback SlugFn Derive a slug from the Nuxt context.
 * @param {import('nuxt').Context} context The Nuxt context.
 * @return {string} The slug.
 */

/**
 * Creates a generic WP post mixin.
 * @param {('post'|'page')} type The type of post.
 * @param {SlugFn|string} slugFn The slug function or the slug itself.
 * @returns {import('vue').ComponentOptions} The WP post mixin.
 */
function wpPost(type, slugFn) {
  return {
    async asyncData(context) {
      if (context.payload) {
        return context.payload;
      }

      let slug = slugFn;
      if (typeof slugFn === 'function') {
        slug = slugFn(context);
      }

      let post;
      try {
        post = await context.$wpJson[`${type}s`].getBySlug(slug);
      }
      catch (err) {
        console.error(err);
      }

      return { [type]: post };
    },

    head() {
      const postOrPage = this.post || this.page;

      if (postOrPage) {
        return yoastToVueMeta(postOrPage);
      }

      return undefined;
    }
  };
}

/**
 * Creates a WP post mixin.
 * @param {SlugFn|string} slugFn The slug function or the slug itself.
 * @returns {import('vue').ComponentOptions} The WP post mixin.
 */
export function post(slugFn) {
  return wpPost('post', slugFn);
}

/**
 * Creates a WP page mixin.
 * @param {SlugFn|string} slugFn The slug function or the slug itself.
 * @returns {import('vue').ComponentOptions} The WP page mixin.
 */
export function page(slugFn) {
  return wpPost('page', slugFn);
}
