export default function yoastToVueMeta(post) {
  const title = post.yoast_head_json.og_title || post.title.rendered;

  const meta = Object.entries(post.yoast_head_json).reduce((meta, [key, value]) => {
    switch (key) {
      case 'og_title':
        meta.push({
          hid: 'og:title',
          property: 'og:title',
          content: value
        });
        break;

      case 'og_locale':
        meta.push({
          hid: 'og:locale',
          property: 'og:locale',
          content: value
        });
        break;

      case 'og_description':
        meta.push({
          hid: 'description',
          name: 'description',
          content: value
        });
        meta.push({
          hid: 'og:description',
          property: 'og:description',
          content: value
        });
        break;

      case 'og_type':
        meta.push({
          hid: 'og:type',
          property: 'og:type',
          content: value
        });
        break;

      case 'og_site_name':
        meta.push({
          hid: 'og:site_name',
          property: 'og:site_name',
          content: value
        });
        break;

      case 'og_image':
        meta.push({
          hid: 'og:image:url',
          property: 'og:image:url',
          content: value.url
        });
        meta.push({
          hid: 'og:image:type',
          property: 'og:image:type',
          content: value.type
        });
        meta.push({
          hid: 'og:image:width',
          property: 'og:image:width',
          content: value.width
        });
        meta.push({
          hid: 'og:image:height',
          property: 'og:image:height',
          content: value.height
        });
        break;

      case 'twitter_card':
        meta.push({
          hid: 'twitter:card',
          property: 'twitter:card',
          content: value
        });
        break;

      default:
    }

    return meta;
  }, []);

  return {
    title,
    meta
  };
}
